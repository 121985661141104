/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import "./layoutCustom.css"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { Link } from "gatsby"
import Collapse from "@material-ui/core/Collapse"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Button from "@material-ui/core/Button"
import { navigate } from "gatsby"

const Layout = ({ children }) => {
  const year = new Date().getFullYear()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
      // setContentMargin('10px');
    }

    prevOpen.current = open
  }, [open])

  const openLink = link => {
    window.open(link, "_blank")
  }

  const gotoHomePage = () => {
    navigate("/")
  }

  return (
    <>
      <div>
        {/* DESKTOP NAV ------------------------------------------------------- */}
        <Box
          className="nav"
          display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
          style={{ border: "0px black dotted" }}
        >
          <Grid container>
            <Grid container>
              <Grid container className="gridNav">
                <Link to="/">
                  <div
                    className="logo_header"
                    style={{ position: "absolute", margin: "20px 0 0 0" }}
                  ></div>
                </Link>

                <div className="nav">
                  <Grid container>
                    <Grid t={0} item>
                      <Link
                        activeClassName="active"
                        className="navLink"
                        to="/sales"
                      >
                        Sales
                      </Link>
                    </Grid>
                    <Grid t={0} item>
                      <Link
                        activeClassName="active"
                        className="navLink"
                        to="/lettings"
                      >
                        Lettings
                      </Link>
                    </Grid>
                    <Grid t={0} item>
                      <Link
                        activeClassName="active"
                        className="navLink"
                        to="/ourstory"
                      >
                        Our story
                      </Link>
                    </Grid>
                    <Grid t={0} item>
                      <Link
                        activeClassName="active"
                        className="navLink"
                        to="/blog"
                      >
                        Blog
                      </Link>
                    </Grid>
                    <Grid t={0} item>
                      <Link
                        activeClassName="active"
                        className="navLink"
                        to="/contact"
                      >
                        Contact
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* MOBILE NAV ------------------------------------------------------- */}
        <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
          <Grid container>
            <Grid container className="gridNav">
              <Grid container className="grid_nav_mobile">
                {/* style={{ margin:'0px 0 0 0', border:'0px red solid' }}  */}
                <Link to="/">
                  <Grid
                    item
                    className="logo_header"
                    style={{
                      position: "absolute",
                      margin: "10px 0 0 0",
                      border: "0px red solid",
                    }}
                  ></Grid>
                </Link>

                <Grid item className="nav">
                  <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className="mobileMenu"
                  ></Button>
                </Grid>

                <Grid item xs={12} className="mobileMenuStylesHolder">
                  <Collapse in={open}>
                    {/*<MenuList style={{  padding:'20px 20px 0 0', border:'0px blue solid', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'left' }}>*/}

                    <MenuList className="mobileMenuStyles">
                      <MenuItem style={{}}>
                        <Link className="mobileNavLink" to="/sales">
                          Sales
                        </Link>
                      </MenuItem>
                      <MenuItem style={{}}>
                        <Link className="mobileNavLink" to="/lettings">
                          Lettings
                        </Link>
                      </MenuItem>
                      <MenuItem style={{}}>
                        <Link className="mobileNavLink" to="/ourstory">
                          Our Story
                        </Link>
                      </MenuItem>
                      <MenuItem style={{}}>
                        <Link className="mobileNavLink" to="/blog">
                          Blog
                        </Link>
                      </MenuItem>
                      <MenuItem style={{}}>
                        <Link className="mobileNavLink" to="/contact">
                          Contact
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </Collapse>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <main className="mainContent">{children}</main>
        <footer>
          <Grid container className="grid4">
            <Grid item xs={12}>
              <Grid container className="footer_inner">
                <Grid item className="footer_inner_mobile">
                  <Grid item className="footerlinks1a">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="footerLink"
                      name="Landlord Fees"
                      href="https://db.brownandkay-ejfw.temp-dns.com/wp-content/Landlord%20Fees%20-%202020.pdf"
                    >
                      Landlord Fees
                    </a>
                  </Grid>
                  <Grid item className="footerlinks2a">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="footerLink"
                      name="Tenant Info"
                      href="https://db.brownandkay-ejfw.temp-dns.com/wp-content/Application Form 2020.pdf"
                    >
                      Tenant info
                    </a>
                  </Grid>
                  <Grid item className="footerlinks2a">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="footerLink"
                      name="Complaint Procedure"
                      href="http://db.brownandkay-ejfw.temp-dns.com/wp-content/uploads/2022/04/Brown-and-Kay-TPO_In_House_Complaints_Procedure.pdf"
                    >
                      Complaint Procedure
                    </a>
                  </Grid>
                  <Grid item className="footerlinks2a">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="footerLink"
                      name="Money Shield Certification"
                      href={`/pdfs/Mandy_Kay_Stephen_Kay_scheme_cert.pdf`}
                    >
                      Money Shield Certification
                    </a>
                  </Grid>
                  <Grid item className="footerlinks2a">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="footerLink"
                      name="FAQs"
                      href="https://db.brownandkay-ejfw.temp-dns.com/wp-content/10 Most Frequently Asked Questions 2020.pdf"
                    >
                      FAQs
                    </a>
                  </Grid>
                </Grid>

                <Grid
                  onClick={gotoHomePage}
                  item
                  className="logo_footer"
                ></Grid>

                <Grid item className="footerlinks1">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="footerLink"
                    name="Landlord Fees"
                    href="https://db.brownandkay-ejfw.temp-dns.com/wp-content/Landlord%20Fees%20-%202020.pdf"
                  >
                    Landlord Fees
                  </a>
                </Grid>
                <Grid item className="footerlinks2">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="footerLink"
                    name="Tenant Info"
                    href="https://db.brownandkay-ejfw.temp-dns.com/wp-content/Application Form 2020.pdf"
                  >
                    Tenant info
                  </a>
                </Grid>
                <Grid item className="footerlinks2">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="footerLink"
                    name="Complaint Procedure"
                    href="http://db.brownandkay-ejfw.temp-dns.com/wp-content/uploads/2022/04/Brown-and-Kay-TPO_In_House_Complaints_Procedure.pdf"
                  >
                    Complaint Procedure
                  </a>
                </Grid>
                <Grid item className="footerlinks2">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="footerLink"
                    name="Money Shield Certification"
                    href={`/pdfs/Mandy_Kay_Stephen_Kay_scheme_cert.pdf`}
                  >
                    Money Shield Certification
                  </a>
                </Grid>
                <Grid item className="footerlinks2">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="footerLink"
                    name="FAQs"
                    href="https://db.brownandkay-ejfw.temp-dns.com/wp-content/10 Most Frequently Asked Questions 2020.pdf"
                  >
                    FAQs
                  </a>
                </Grid>
                <Grid
                  onClick={() => openLink("https://www.tpos.co.uk/")}
                  item
                  className="footerlinks4"
                ></Grid>
                <Grid
                  // onClick={() => openLink("https://money-shield.co.uk")}
                  item
                  className="footerlinks4aa"
                ></Grid>
                <Grid
                  onClick={() => openLink("https://money-shield.co.uk")}
                  item
                  className="footerlinks4a"
                ></Grid>
                <Grid
                  onClick={() =>
                    openLink("https://www.relocation-agent-network.co.uk/")
                  }
                  item
                  className="footerlinks5"
                ></Grid>
                <Grid
                  onClick={() =>
                    openLink(
                      "https://www.facebook.com/BrownandKayPooleandWestbourne/"
                    )
                  }
                  item
                  className="footerlinks6"
                ></Grid>
                <Grid
                  onClick={() =>
                    openLink("https://www.instagram.com/brownandkay/")
                  }
                  item
                  className="footerlinks7"
                ></Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className="copyright">
            <Grid container className="grid1">
              © Copyright {year} Brown & Kay - All Rights Reserved
            </Grid>
          </Grid>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
